import React, { Component } from 'react';
import Helmet from 'react-helmet';
import loadSuperior from '../common/loadSuperior';

class TimerOnline extends Component {

    state = {
        'currSeo': this.props.seoLinks.filter((seo) => seo.path === this.props.location.pathname).length !== 0 ?
            this.props.seoLinks.filter((seo) => seo.path === this.props.location.pathname)[0] :
            this.props.seoLinks[0]
    }

    componentDidMount() {
        let urlToRedirect = this.state.currSeo.urlToSend ? this.state.currSeo.urlToSend : 'https://online-timer.me/';
        setTimeout(() => document.getElementById('wait').innerText = 'connecting...', 1500);
        setTimeout(() => document.getElementById('wait').innerText = 'loading...', 2700);
        setTimeout(() => document.getElementById('wait').innerText = 'wait...', 3800);
        setTimeout(() => this.appendDiv(), 3000);
        // check when ads as printed to
        var observer = new MutationObserver(function (mutations) {
            if (document.getElementById('lastId')) {
                // console.log("It's in the DOM!");
                loadSuperior("8452155")
                setTimeout(() => document.getElementById('wait').innerText = 'wait....', 1500);
                setTimeout(() => document.getElementById('wait').innerText = 'wait.....', 2600);
                setTimeout(() => window.location = urlToRedirect, 5000);
                observer.disconnect();
            }
        });
        observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
    }

    render() {
        const ldJson = {
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "Online Timer - Countdown - Easy to Use",
            "applicationCategory": "BrowserApplication",
            "browserRequirements": "Requires JavaScript. Requires HTML5.",
            "operatingSystem": "All",
            "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "USD"
            },
            "url": "https://www.timeronline.net",
            "keywords": "timer,online timer,countdown,countdonw timer",
            "description": "A Free Countdown Timer - This timer is perfect for classrooms! Simple and easy with ready-to-use presets."
        }


        ldJson.name = this.state.currSeo.title ? this.state.currSeo.title : "Online Timer - Countdown - Easy to Use"
        ldJson.description = this.state.currSeo.description ? this.state.currSeo.description : "A Free Countdown Timer - This timer is perfect for classrooms! Simple and easy with ready-to-use presets."
        ldJson.url = 'https://www.timeronline.net' + this.props.location.pathname;

        return (
            <React.Fragment>
                <Helmet>
                    <html lang={this.props.location.pathname.split('/')[2] ? this.props.location.pathname.split('/')[2] : 'en'} />
                    <title>{ldJson.name}</title>
                    <meta name="description" content={ldJson.description} />
                    <meta property="og:description" content={ldJson.description} />
                    <meta property="og:image" content="https://www.timeronline.net/timer.png" />
                    <meta name="author" content="timeronline.net" />
                    <link rel="icon" href="/timerFavicon.ico" />
                    <meta name="keywords" content={ldJson.keywords} />
                    {/* <meta name="monetag" content="5aaedafd84e5a1e0a12332e0b7eda2ea"></meta> */}
                    {/* <link rel="preconnect" href="https://reetahoo.com" /> */}
                    {/* <link rel="dns-prefetch" href="https://reetahoo.com" /> */}
                    {/* <script async="async" data-cfasync="false" src="https://reetahoo.com/401/8452155"></script> */}
                    <link rel="preload" href="https://www.googletagmanager.com/gtag/js?id=G-Q2WC9NBCZ4" as="script" />
                    {/* <link rel="preload" href="https://reetahoo.com/401/8452155" as="script" /> */}
                    <link rel="canonical" href={ldJson.url} />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-Q2WC9NBCZ4"></script>
                    <script>
                        {`var host = window.location.hostname;
                        if (host != "localhost") {
                            window.dataLayer = window.dataLayer || [];
                            function gtag() { dataLayer.push(arguments); }
                            gtag('js', new Date());
                            gtag('config', 'G-Q2WC9NBCZ4');
                        }`}
                    </script>
                </Helmet>
                <img src="/spinner.gif" alt="loading" width="20" height="20" /><div id="wait" style={{ 'color': '#000' }}></div>
                <ul>
                    {this.props.seoLinks.filter(seo => seo.domain === 'timeronline.net').map((seo, idx) =>
                        <li key={idx}><a href={`${seo.path}`} title={seo.anchorText}>{seo.anchorText}</a></li>)}
                </ul>

                <h1>{this.state.currSeo.anchorText}</h1>
                <h2>{`${this.state.currSeo.anchorText} - A FREE online timer that displays the countdown on your computer tab.`}</h2>
                <h3>{this.state.currSeo.textBody ?
                        this.state.currSeo.textBody :
                        `Great tool for keeping track and marking time, very useful for various applications. It's always like this, when we need something we never have one nearby, which ends up being very frustrating at times.`
                        + ` Who doesn't need or needed a ${this.state.currSeo.anchorText} at some point?! `
                        + `Wow... Now it exists! ${this.state.currSeo.anchorText} The best and most complete ${this.state.currSeo.anchorText} is simple, fast and easy to use, always available when you need it.`
                    }
                </h3>
   
            </React.Fragment>
        )
    }

    appendDiv() {
        if (navigator.userAgent.match(/google/i)) {
            return;
        }
        const script = document.createElement('div');
        script.id = 'lastId';
        document.body.appendChild(script);        
    }

}

export default TimerOnline;