const loadSuperior = (id) => {
  const existingScript = document.getElementById('superiorTag');
  const host = (typeof window !== 'undefined') ? window.location.hostname : '';
  if (!existingScript && host.indexOf('localhost') === -1) {
    const script = document.createElement('script');
    script.src = `https://reetahoo.com/401/${id}`;
    script.id = 'superiorTag';
    script.async = 'async';
    script.dataset.cfasync = 'false';
    document.body.appendChild(script);
  }
};
export default loadSuperior;